import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function FieldError({ error, classes, id, isLiveRegion }) {
  if (!error && !isLiveRegion) {
    return null;
  }
  return (
    <div id={id} role={isLiveRegion ? 'alert' : undefined} className={classNames('text-danger small', classes)}>
      {error}
    </div>
  );
}

FieldError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  classes: PropTypes.string,
  id: PropTypes.string.isRequired,
  isLiveRegion: PropTypes.bool,
};

FieldError.defaultProps = {
  error: null,
  classes: '',
  isLiveRegion: false,
};
