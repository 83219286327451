import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse as ReactCollapse } from '@kunukn/react-collapse'; // eslint-disable-line no-restricted-imports
import './collapse.scss';

const PX_IN_REM = 16;

export function Collapse(props) {
  const { className, isOpen, collapseHeight } = props;
  const collapseRef = useRef();

  const collapseHeightPixels = useMemo(() => {
    switch (true) {
      case !collapseHeight: {
        return null;
      }
      case collapseHeight.includes('rem'): {
        return parseFloat(collapseHeight) * PX_IN_REM;
      }
      case collapseHeight.includes('px'): {
        return parseFloat(collapseHeight);
      }
      default: {
        return null;
      }
    }
  }, [collapseHeight]);

  useEffect(() => {
    if (!collapseRef.current || !collapseHeightPixels) {
      return;
    }

    const { scrollHeight } = collapseRef.current;
    const containerTop = collapseRef.current.getBoundingClientRect().top;
    const containerHeight = isOpen ? scrollHeight : collapseHeightPixels;

    const interactiveElements = collapseRef.current.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    );

    interactiveElements.forEach(element => {
      element.classList.toggle(
        'collapse-invisible',
        element.getBoundingClientRect().top + element.offsetHeight - containerTop >= containerHeight
      );
    });
  }, [isOpen, collapseHeightPixels]);

  const onInit = useCallback(({ node }) => {
    collapseRef.current = node;
  }, []);

  return <ReactCollapse {...props} onInit={onInit} className={classnames('react-collapse', className)} />;
}

Collapse.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  collapseHeight: PropTypes.string,
};

Collapse.defaultProps = {
  className: null,
  isOpen: false,
  collapseHeight: '0px',
};
