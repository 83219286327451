import PropTypes from 'prop-types';
import { useRestoreFocus } from 'site-modules/shared/hooks/use-restore-focus';

/**
 * Component restores focus back to the element that was focused when the RestoreFocus mounted, after it unmounts
 * or "isInnerScopeActive" prop changes to false.
 *
 * It could be used by custom modals or drawers, which are written as class components.
 * For functional components use useRestoreFocus hook instead
 *
 * Main use case of this component is modals with "trapped" focus.
 * Focus should be restored to the element that was focused before opening a modal when it closes.
 * Note that FocusScope component from react-aria library already has such capabilities.
 * You just need to pass "restoreFocus" prop.
 * In most of the cases prefer to use FocusScope with "restoreFocus" prop for trapping focus and restoring it.
 * But FocusScope has one limitation: it requires to be unmounted to properly restore focus.
 * In some cases we don't unmount a modal, but hide it visually via display css property for performance reasons.
 * In such cases RestoreFocus component takes the stage. Just use "isInnerScopeActive" prop.
 * Note that FocusScope component from react-aria can be used together with RestoreFocus. In this case FocusScope will trap focus inside a modal
 * and RestoreFocus will restore focus when modal hides.
 * Example:
 *   <RestoreFocus isInnerScopeActive={isOpen}>
 *     <FocusTrap contain={isOpen}>
 *       // Modal jsx
 *     </FocusTrap>
 *   </RestoreFocus>
 */
export function RestoreFocus(props) {
  const { isInnerScopeActive, children } = props;
  useRestoreFocus(isInnerScopeActive);

  return children;
}

RestoreFocus.propTypes = {
  isInnerScopeActive: PropTypes.bool,
  children: PropTypes.node,
};

RestoreFocus.defaultProps = {
  isInnerScopeActive: undefined,
  children: null,
};
