import { useEffect, useRef } from 'react';

/**
 * Hook restores focus back to the element that was focused when component mounted, after it unmounts
 * or "isInnerScopeActive" prop changes to false.
 *
 * Main use case of this hook is modals with "trapped" focus.
 * Focus should be restored to the element that was focused before opening a modal when it closes.
 * Note that FocusScope component from react-aria library already has such capabilities.
 * You just need to pass "restoreFocus" prop.
 * In most of the cases prefer to use FocusScope with "restoreFocus" prop for trapping focus and restoring it.
 * But FocusScope has one limitation: it requires to be unmounted to properly restore focus.
 * In some cases we don't unmount a modal, but hide it visually via display css property for performance reasons.
 * In such cases useRestoreFocus hook takes the stage. Just use "isInnerScopeActive" param.
 * Note that FocusScope component from react-aria can be used together with useRestoreFocus. In this case FocusScope will trap focus inside a modal
 * and useRestoreFocus will restore focus when modal hides.
 * Example:
 *   useRestoreFocus(isOpen);
 *
 *   <FocusTrap contain={isOpen}>
 *     // Modal jsx
 *   </FocusTrap>
 * @param {boolean} [isInnerScopeActive] - controls if element should be focused. Can be used
 * when component does not unmount (not removed from the DOM), but only disappears/appears visually with the help of css
 */
export function useRestoreFocus(isInnerScopeActive = true) {
  const prevActiveNode = useRef();

  useEffect(() => {
    if (isInnerScopeActive) {
      prevActiveNode.current = document.activeElement;
    }
    return () => {
      requestAnimationFrame(() => {
        if (isInnerScopeActive && prevActiveNode.current && document.body.contains(prevActiveNode.current)) {
          prevActiveNode.current.focus();
        }
      });
    };
  }, [isInnerScopeActive]);
}
