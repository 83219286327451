import React from 'react';
import PropTypes from 'prop-types';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildPubStateModelYearPath, VehicleModel } from 'client/data/models/vehicle';
import { PUB_STATES } from 'client/constants/pub-states';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

export function YearDropdownUI({ isStyled, years, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  return <SelectComponent {...restProps} name="select-year" options={years} />;
}

YearDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
  years: PropTypes.arrayOf(PropTypes.shape({ year: PropTypes.number })),
};

YearDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
  years: [],
};

const stateToPropsConfig = {
  years: bindToPath(({ pubStates }) => buildPubStateModelYearPath(pubStates), VehicleModel),
};

export const YearDropdown = connectToModel(YearDropdownUI, stateToPropsConfig);

YearDropdown.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
  years: PropTypes.arrayOf(PropTypes.shape({ year: PropTypes.number })),
  pubStates: PropTypes.arrayOf(PropTypes.string),
};

YearDropdown.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
  years: [],
  pubStates: [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED],
};
