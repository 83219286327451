/**
 * Grid breakpoints
 *
 * Define the minimum dimensions at which your layout will change,
 * adapting to different screen sizes, for use in media queries.
 */
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  max: 100000,
};
