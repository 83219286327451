import React from 'react';
import PropTypes from 'prop-types';
import './section-heading.scss';

function Linkout({ children, href, tracking }) {
  const { id, value } = tracking;
  const trackingAttrs = {
    'data-tracking-id': id,
    'data-tracking-value': value,
  };

  return (
    <a href={href} className="linkout float-right" {...trackingAttrs}>
      {children}
    </a>
  );
}

Linkout.propTypes = {
  tracking: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Linkout.defaultProps = {
  tracking: {
    id: null,
    value: null,
  },
};

export function SectionHeading({ children, linkout, linkoutText, textClassName, containerClassName, tag }) {
  const Tag = tag;
  return (
    <Tag className={containerClassName}>
      <span className={textClassName}>{children}</span>
      {linkout && linkoutText && <Linkout {...linkout}>{linkoutText}</Linkout>}
    </Tag>
  );
}

SectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
  linkout: PropTypes.shape({
    href: PropTypes.string,
    tracking: PropTypes.object,
  }),
  linkoutText: PropTypes.string,
  textClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  tag: PropTypes.string,
};

SectionHeading.defaultProps = {
  linkout: null,
  linkoutText: '',
  tracking: '',
  textClassName: 'font-weight-bold text-uppercase text-primary',
  containerClassName: 'py-0_75 px-1 bg-gray-lighter section-heading small d-flex flex-column d-lg-block',
  tag: 'header',
};
