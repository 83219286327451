import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { noop } from 'lodash';
import { Link } from 'client/site-modules/shared/components/link/link';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';

import './tab.scss';

export class Tab extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    tabId: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    link: PropTypes.string,
    trackingId: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    disabled: PropTypes.bool,
    ariaControls: PropTypes.string,
  };

  static defaultProps = {
    isActive: false,
    trackingId: null,
    className: 'pt-0 pb-0_5 px-1',
    icon: '',
    labelClassName: 'size-16 pb-0_5 font-weight-normal',
    onClick: noop,
    link: '',
    iconClassName: '',
    disabled: false,
    ariaControls: undefined,
  };

  handleClick = e => {
    e.preventDefault();
    this.props.onClick(this.props.tabId);
  };

  render() {
    const {
      isActive,
      label,
      trackingId,
      dataTest,
      className,
      labelClassName,
      link,
      tabId,
      icon,
      iconClassName,
      disabled,
      ariaControls,
    } = this.props;

    return (
      <Button
        id={tabId}
        role="tab"
        aria-controls={ariaControls}
        aria-selected={isActive}
        className={classnames('tab', className)}
        color="link"
        active={isActive}
        disabled={disabled}
        {...trackingId && { 'data-tracking-id': trackingId }}
        {...dataTest && { 'data-test': dataTest, 'data-testid': dataTest }}
        {...(link
          ? {
              tag: Link,
              to: link,
            }
          : { onClick: this.handleClick })}
      >
        <span className={classnames('tab-label text-capitalize', labelClassName)}>
          {icon && !disabled && <span className={classnames(icon, iconClassName)} aria-hidden />}
          {disabled && (
            <span className="spinner-container d-inline-block mr-0_5">
              <Spinner size={16} thickness={3} />
            </span>
          )}
          {label}
        </span>
      </Button>
    );
  }
}
