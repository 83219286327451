import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { getAppraisalLink } from 'site-modules/shared/utils/appraisal-link-constructor';
import { getSellCarPageUrl } from 'site-modules/shared/utils/editorial-helper';
import { Link } from 'site-modules/shared/components/link/link';
import { MakeDropdown } from 'site-modules/shared/components/ymms-dropdowns/make-dropdown/make-dropdown';
import { ModelDropdown } from 'site-modules/shared/components/ymms-dropdowns/model-dropdown/model-dropdown';
import { YearDropdown } from 'site-modules/shared/components/ymms-dropdowns/year-dropdown/year-dropdown';
import classnames from 'classnames';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { PUB_STATES } from 'client/constants/pub-states';
import { isEqual, isEmpty } from 'lodash';

import './mmy-tab-content.scss';

const CREATIVE_ID = 'appraise_sell_vehicle_entry';

const CTA_TEXT = 'Go';

export class MmyTabContent extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    isAppraiserPath: PropTypes.bool,
    makeModelEnabled: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    ctaText: PropTypes.string,
    preselectedMMY: PropTypes.shape({}),
    tabId: PropTypes.string.isRequired,
    isTabActive: PropTypes.bool,
  };

  static defaultProps = {
    isMobile: false,
    isAppraiserPath: false,
    makeModelEnabled: false,
    isEmbedded: false,
    ctaText: CTA_TEXT,
    preselectedMMY: {},
    isTabActive: false,
  };

  state = {
    make: null,
    model: null,
    year: null,
    isMakeModelEnabled: !this.props.isMobile || this.props.makeModelEnabled,
    isRedirecting: false,
  };

  componentDidMount() {
    this.setMMYIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { isTabActive } = this.props;
    const { isRedirecting } = this.state;

    this.setMMYIfNeeded(prevProps);

    if (!isTabActive && isRedirecting) {
      this.resetIsRedirecting();
    }
  }

  componentWillUnmount() {
    this.resetIsRedirecting();
    clearTimeout(this.timerId);
  }

  setMMY = ymm => {
    this.setState({
      make: ymm.make,
      model: ymm.model,
      year: ymm.year,
    });
  };

  setMMYIfNeeded = (prevProps = {}) => {
    const { preselectedMMY } = this.props;

    if (!isEmpty(preselectedMMY) && !isEqual(preselectedMMY, prevProps.preselectedMMY)) {
      this.setMMY(preselectedMMY);
    }
  };

  setYear = ({ year }) =>
    this.setState({
      year,
      make: null,
      model: null,
    });

  setMake = ({ niceName }) => this.setState({ make: niceName, model: null });

  setModel = ({ niceId }) => this.setState({ model: niceId });

  timerId = null;

  enableMakeModel = () => this.setState({ isMakeModelEnabled: true });

  fireTracking = ({ eventType, actionName, actionCause, subactionName, value }) => {
    EventToolbox.fireTrackAction({
      event_type: eventType,
      event_data: {
        action_name: actionName,
        subaction_name: subactionName,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: actionCause,
        creative_id: CREATIVE_ID,
        value,
      },
    });
  };

  trackSubmit = () => {
    const { ctaText } = this.props;
    const { year, make, model } = this.state;

    this.timerId = setTimeout(() => {
      this.resetIsRedirecting();
    }, 5000);

    this.setState({
      isRedirecting: true,
    });

    const mmySelectAction = {
      eventType: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      actionName: TrackingConstant.ACTION_MMY_SELECT,
      actionCause: TrackingConstant.SELECT_CHANGE,
    };

    this.fireTracking({ ...mmySelectAction, subactionName: TrackingConstant.YEAR_SELECT, value: year });
    this.fireTracking({ ...mmySelectAction, subactionName: TrackingConstant.MAKE_SELECT, value: make });
    this.fireTracking({ ...mmySelectAction, subactionName: TrackingConstant.MODEL_SELECT, value: model });
    this.fireTracking({
      eventType: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      actionName: TrackingConstant.ACTION_TOOL_SELECT,
      actionCause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subactionName: TrackingConstant.APPRAISE_YOUR_CAR,
      value: ctaText,
    });
  };

  resetIsRedirecting = () => {
    this.setState({
      isRedirecting: false,
    });
  };

  render() {
    const { isAppraiserPath, isMobile, isEmbedded, ctaText, tabId } = this.props;
    const { year, make, model, isMakeModelEnabled, isRedirecting } = this.state;
    const isMMY = !!(make && model && year);
    let link = '';

    if (isMMY) {
      link = isAppraiserPath ? getAppraisalLink({ make, model, year }) : getSellCarPageUrl({ make, model, year });
    }

    const isBtnDisabled = isMakeModelEnabled ? !isMMY : !year;
    const isDisabled = isBtnDisabled || isRedirecting;

    return (
      <Row className="mmy-tab-content">
        <Col xs={12} lg={isEmbedded ? 12 : 9}>
          <Form autoComplete="off">
            <Row>
              <Col xs={isMakeModelEnabled ? 12 : 8} md={3}>
                <FormGroup>
                  <YearDropdown
                    name="select-year"
                    toggle={'Year'}
                    valueKey="year"
                    labelKey="year"
                    labelText="Year"
                    value={year}
                    onChange={this.setYear}
                    isStyled
                    pubStates={[PUB_STATES.USED, PUB_STATES.NEW_USED]}
                    isLabelHidden
                    id={`year-${tabId}`}
                  />
                </FormGroup>
              </Col>

              {isMakeModelEnabled && (
                <Col xs={12} md={3}>
                  <FormGroup>
                    <MakeDropdown
                      year={year}
                      name="select-make"
                      toggle={'Make'}
                      valueKey="niceName"
                      labelKey="name"
                      labelText="Make"
                      onChange={this.setMake}
                      value={make}
                      isStyled
                      isLabelHidden
                      pubStates={[PUB_STATES.USED, PUB_STATES.NEW_USED]}
                      id={`make-${tabId}`}
                    />
                  </FormGroup>
                </Col>
              )}

              {isMakeModelEnabled && (
                <Col xs={12} md={3}>
                  <FormGroup>
                    <ModelDropdown
                      make={make}
                      year={year}
                      name="select-model"
                      toggle={'Model'}
                      valueKey="niceId"
                      labelKey="name"
                      labelText="Model"
                      value={model}
                      onChange={this.setModel}
                      isStyled
                      isLabelHidden
                      pubStates={[PUB_STATES.USED, PUB_STATES.NEW_USED]}
                      id={`model-${tabId}`}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={isMakeModelEnabled ? 12 : 4} md="auto">
                <Button
                  size="lg"
                  tag={isMakeModelEnabled && !isDisabled ? Link : undefined}
                  to={isMakeModelEnabled && !isDisabled ? link : undefined}
                  onClick={isMakeModelEnabled ? this.trackSubmit : this.enableMakeModel}
                  color="success"
                  className={classnames(
                    'appraiser-sell-submit text-transform-none text-white btn-responsive-md-down mb-1',
                    {
                      'w-100': isMakeModelEnabled && isMobile,
                    }
                  )}
                  data-tracking-value={isMMY ? `${year}_${make}_${model}` : null}
                  disabled={isDisabled}
                  tabIndex={isDisabled ? -1 : 0}
                  aria-labelledby={`cta-${tabId} ${tabId} year-${tabId} make-${tabId} model-${tabId}`}
                >
                  {isRedirecting && (
                    <span className="spinner-wrapper pr-0_5">
                      <Spinner size={14} thickness={1} color="white" />
                    </span>
                  )}
                  <span id={`cta-${tabId}`} className="size-16">
                    {ctaText}
                  </span>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
