import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildYearMakesPath, VehicleModel } from 'client/data/models/vehicle';

const MakeDropdownStateToPropsConfig = {
  options: bindToPath(({ year, pubStates }) => year && buildYearMakesPath(year, pubStates), VehicleModel),
};

export function MakeDropdownUI({ isStyled, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  return <SelectComponent {...restProps} />;
}

MakeDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
};

MakeDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
};

export const MakeDropdown = connectToModel(MakeDropdownUI, MakeDropdownStateToPropsConfig);
