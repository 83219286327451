import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { getClosestSizeToFit, getImageUrl } from 'client/utils/image-helpers';

const Image = ({
  imageId,
  width,
  height,
  source,
  alt,
  title,
  className,
  onError,
  onLoad,
  errorImage,
  lazy,
  importance,
  ariaHidden,
}) => {
  const [isError, setIsError] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    setIsError(false);
  }, [imageId]);

  const { imgHeight, imgWidth, imgUrl } = useMemo(() => {
    let imgUrlInner;
    let imgWidthInner;
    let imgHeightInner;

    if (!height && width && Number.isInteger(width)) {
      const [w, h] = getClosestSizeToFit(width);
      imgUrlInner = getImageUrl(imageId, w, source);
      imgWidthInner = `${w}`;
      imgHeightInner = `${h}`;
    } else {
      imgUrlInner = getImageUrl(imageId, undefined, source);
      imgWidthInner = width;
      imgHeightInner = height;
    }

    if (isError) imgUrlInner = errorImage;

    return {
      imgHeight: imgHeightInner,
      imgWidth: imgWidthInner,
      imgUrl: imgUrlInner,
    };
  }, [height, width, isError, errorImage, imageId, source]);

  useEffect(() => {
    // Call onLoad or onError props for the cases when react hydrates component after image loaded.
    // Otherwise, consumer will not get notified that image has completed loading.
    if (imgRef.current && imgRef.current.complete) {
      if (imgRef.current.naturalWidth === 0) {
        if (errorImage) {
          setIsError(true);
        }
        onError();
      } else {
        onLoad();
      }
    }
  }, [errorImage, onError, onLoad, setIsError]);

  function onErrorHandler() {
    if (errorImage) {
      setIsError(true);
    }
    onError();
  }

  return (
    <img
      data-test="image"
      src={imgUrl}
      width={imgWidth}
      height={imgHeight}
      alt={alt}
      className={classNames(className)}
      onError={onErrorHandler}
      onLoad={onLoad}
      ref={imgRef}
      title={title}
      loading={lazy ? 'lazy' : undefined}
      importance={importance}
      aria-hidden={ariaHidden}
    />
  );
};

Image.propTypes = {
  imageId: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  errorImage: PropTypes.string,
  lazy: PropTypes.bool,
  importance: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

Image.defaultProps = {
  alt: '',
  title: undefined,
  className: '',
  onError: noop,
  onLoad: noop,
  errorImage: '',
  lazy: false,
  importance: null,
  ariaHidden: undefined,
  width: undefined,
  height: undefined,
};

export { Image };
