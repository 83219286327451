import { omitBy } from 'lodash';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { URL_PATHS } from 'site-modules/shared/constants/appraisal/paths';

/**
 * @param {string} make
 * @param {string} model
 * @param {number} year
 * @param {object} queryParams
 * @param {string} queryParams.vin (optional)
 * @param {string} queryParams.styleIds (optional)
 * @param {string} queryParams.colorId (optional)
 * @param {string} queryParams.modsId (optional)
 * @param {string} queryParams.emo (optional, "true"/"false" string)
 * @param {boolean} isAppExtensionPage (optional)
 * @returns {String} - Appraisal Value url
 * */
export function getAppraisalLink({ make, model, year, queryParams = {}, isAppExtensionPage }) {
  const queryString = decodeURIComponent(objectToQueryString(omitBy(queryParams, param => !param)));
  let url = `/${make}/${model}/${year}/appraisal-value/`;

  if (isAppExtensionPage) url += 'index.app';

  return `${url}${queryString.length ? '?' : ''}${queryString}`;
}

export function getAppraisalLinkFromVehicle({ vehicle, isAppExtensionPage, ...queryParams }) {
  const { makeSlug, modelSlug, year, vin, edmundsStyleId } = vehicle;
  return getAppraisalLink({
    make: makeSlug,
    model: modelSlug,
    year,
    queryParams: { ...queryParams, vin, styleIds: edmundsStyleId },
    isAppExtensionPage,
  });
}

/**
 * @param {boolean} isAppExtensionPage (optional)
 * @returns {String} - Appraisal Landing url
 * */
export function getAppraisalLandingLink({ isAppExtensionPage }) {
  let url = URL_PATHS.APPRAISAL_LANDING;

  if (isAppExtensionPage) url += 'index.app';

  return url;
}

export function getAppraisalRenewalLink({ queryParams } = {}) {
  let result = '/appraisal/renewal/';

  const queryString = queryParams && objectToQueryString(queryParams);
  if (queryString) {
    result += `?${queryString}`;
  }

  return result;
}
