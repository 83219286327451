import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { pickBy, noop } from 'lodash';
import { FocusScope } from '@react-aria/focus';
import { getOriginalBodyPadding, conditionallyUpdateScrollbar, setScrollbarWidth } from 'reactstrap/lib/utils';
import { CSSTransition } from 'react-transition-group';
import { EventToolbox } from 'client/utils/event-toolbox';
import { scrollToPos } from 'client/utils/scroll';
import { RestoreFocus } from 'site-modules/shared/components/restore-focus/restore-focus';
import { DRAWER_EVENTS } from './events';

import './drawer-v2.scss';

const DRAWERS_CONTAINER_SELECTOR = 'drawers-container';
const PAGE_SELECTOR = 'edm-page';

/**
 * Adds required classes to the documentElement, body
 * @return {void}
 */

const toggleRequiredClasses = (isOpen, side) => {
  const bodyClasses = ['modal-open', 'drawer-open', `drawer-${side}`];
  const htmlClasses = ['modal-open'];
  if (isOpen) {
    document.body.classList.add(...bodyClasses);
    document.documentElement.classList.add(...htmlClasses);
  } else {
    document.body.classList.remove(...bodyClasses);
    document.documentElement.classList.remove(...htmlClasses);
  }
};

/**
 * Restore scroll position after closing the drawer
 * Restore scroll position. When body has "position: fixed" browsers scroll page to the top.
 * "position: fixed" on body element is needed to prevent page scrolling behind the drawer in Safari on iOS.
 * See body styles details in drawer.scss
 * @return {void}
 */
const restoreScrollPosition = isOpen => {
  if (isOpen) {
    const currentScrollPosition = window.scrollY;
    document.body.style.top = `${-currentScrollPosition}px`;
  } else {
    const offsetY = Math.abs(parseInt(document.body.style.top || 0, 10));
    if (offsetY) {
      document.body.style.removeProperty('top');
      scrollToPos(0, offsetY);
    }
  }
};

export const DrawerV2 = memo(
  forwardRef((props, ref) => {
    const {
      isOpen,
      side,
      size,
      toggle,
      backdrop,
      keyboard,
      onShow,
      onHide,
      classNames,
      children,
      ariaLabel,
      trapFocus,
      unmountOnExit,
      drawerContainerSelector,
      id,
      noInitialFocus,
      onDestroy,
    } = props;

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const drawersContainer = useRef(null);
    const originalBodyPadding = useRef(null);
    const dialogRef = useRef(null);
    const containerRef = useRef(null);
    const contentRef = useRef(null);

    useImperativeHandle(
      ref,
      () => ({
        dialog: dialogRef.current,
        container: containerRef.current,
        content: contentRef.current,
      }),
      []
    );

    /**
     * Checks if the drawers container exists, and if not, creates it.
     * @return {void}
     */
    const createContainer = useCallback(() => {
      const drawerContainer = document.querySelector(`.${drawerContainerSelector}`);

      if (drawerContainer) {
        drawersContainer.current = drawerContainer;
      } else {
        const page = document.querySelector(`.${PAGE_SELECTOR}`);
        drawersContainer.current = document.createElement('div');
        drawersContainer.current.classList.add(drawerContainerSelector);
        page.appendChild(drawersContainer.current);
      }
    }, [drawerContainerSelector]);

    const show = useCallback(() => {
      setIsOpenDrawer(true);
      onShow();

      setTimeout(() => {
        requestAnimationFrame(() => {
          conditionallyUpdateScrollbar();
          restoreScrollPosition(true);
          toggleRequiredClasses(true, side);
        });
      });
    }, [onShow, side]);

    const destroy = useCallback(() => {
      toggleRequiredClasses(false, side);
      setScrollbarWidth(originalBodyPadding.current);
      restoreScrollPosition(false);
      onDestroy();
    }, [onDestroy, side]);

    const hide = useCallback(() => {
      setIsOpenDrawer(false);
      onHide();

      setTimeout(() => {
        requestAnimationFrame(() => {
          destroy();
        });
      });
    }, [destroy, onHide]);

    useEffect(() => {
      createContainer();
      originalBodyPadding.current = getOriginalBodyPadding();
    }, [createContainer]);

    // When we remove the DrawerV2 component from the external component and the Drawer is open,
    // we should reset all additional classes from the html and body.
    // Example: flag && <DrawerV2 open={true}/> (flag is false)
    useEffect(() => {
      if (isOpen) {
        show();
      }

      return () => {
        if (isOpen) {
          hide();
        }
      };

      // eslint-disable-next-line
    }, [isOpen]);

    const handleEscape = useCallback(
      e => {
        if (keyboard && e.keyCode === 27) {
          toggle();
        }
      },
      [keyboard, toggle]
    );

    const handleBackdropClick = useCallback(
      e => {
        if (backdrop !== true) return;
        if (e.target && containerRef.current && !containerRef.current.contains(e.target)) {
          toggle();
        }
      },
      [backdrop, toggle]
    );

    const fireEvent = useCallback(() => {
      const eventName = isOpenDrawer ? DRAWER_EVENTS.OPEN : DRAWER_EVENTS.CLOSED;
      EventToolbox.fireCustomEvent(eventName, {}, dialogRef.current);
    }, [isOpenDrawer]);

    const handleEntered = useCallback(() => {
      requestAnimationFrame(() => {
        if (!noInitialFocus) dialogRef.current.focus();
      });
      fireEvent();
    }, [fireEvent, noInitialFocus]);

    const handleExited = useCallback(() => {
      fireEvent();
    }, [fireEvent]);

    const renderBackdrop = () =>
      backdrop && (
        <CSSTransition
          in={isOpenDrawer}
          timeout={300}
          classNames="open-backdrop"
          unmountOnExit={unmountOnExit}
          mountOnEnter
        >
          <div className={classnames('modal-backdrop', classNames.backdrop)} />
        </CSSTransition>
      );

    const renderDrawerContent = () => {
      const args = pickBy(props, (value, key) => key.startsWith('data-') || key.startsWith('aria-'));

      return (
        <CSSTransition
          in={isOpenDrawer}
          timeout={300}
          classNames={`open-${side}`}
          onEntered={handleEntered}
          onExited={handleExited}
          unmountOnExit={unmountOnExit}
          mountOnEnter
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            {...args}
            id={id}
            key="drawer-dialog"
            onClickCapture={handleBackdropClick}
            onKeyUp={handleEscape}
            className={classnames(`modal-drawer drawer-${side} dr-${size} d-block`, classNames.drawer)}
            tabIndex={-1}
            role="dialog"
            aria-hidden={!isOpenDrawer}
            ref={dialogRef}
            aria-label={ariaLabel}
          >
            <RestoreFocus isInnerScopeActive={isOpenDrawer}>
              <FocusScope contain={trapFocus && isOpenDrawer}>
                <div
                  className={classnames('drawer-container modal-dialog', classNames.drawerContainer)}
                  role="document"
                  ref={containerRef}
                >
                  <div
                    className={classnames('drawer-content modal-content', classNames.drawerContent)}
                    ref={contentRef}
                  >
                    {children}
                  </div>
                </div>
              </FocusScope>
            </RestoreFocus>
          </div>
        </CSSTransition>
      );
    };

    if (!drawersContainer.current) return null;

    return createPortal(
      <>
        {renderBackdrop()}
        {renderDrawerContent()}
      </>,
      drawersContainer.current
    );
  })
);

DrawerV2.displayName = 'DrawerV2';

DrawerV2.propTypes = {
  isOpen: PropTypes.bool, // defines whether drawer is open or not, false by default
  side: PropTypes.oneOf(['left', 'right', 'top', 'bottom']), // which side drawer should be open (left, right, top, bottom), left by default
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'full']), // drawer size (xsmall, small, medium or full), medium by default
  toggle: PropTypes.func.isRequired, // drawer toggle handler/callback, should toggle isOpen property
  backdrop: PropTypes.oneOfType([
    // defines whether backdrop is displayed or not, true by default
    PropTypes.bool, //
    PropTypes.oneOf(['static']), // if it's 'static' then it's displayed, but drawer is not closed on click
  ]),
  keyboard: PropTypes.bool, // defines whether drawer listens to keyboard events,
  onShow: PropTypes.func, // callback function called when drawer is shown
  onHide: PropTypes.func, // callback function called when drawer is hidden
  classNames: PropTypes.shape({
    // drawer custom class names
    drawer: PropTypes.string, // root level class name
    drawerContainer: PropTypes.string, // drawer inner container class name
    drawerContent: PropTypes.string, // drawer content container class name
    backdrop: PropTypes.string, // drawer backdrop class name
  }),
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  trapFocus: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  drawerContainerSelector: PropTypes.string,
  id: PropTypes.string,
  noInitialFocus: PropTypes.bool,
  onDestroy: PropTypes.func,
};
DrawerV2.defaultProps = {
  isOpen: false,
  side: 'left',
  size: 'medium',
  backdrop: true,
  keyboard: true,
  onShow: noop,
  onHide: noop,
  classNames: {},
  children: null,
  ariaLabel: null,
  trapFocus: true,
  unmountOnExit: false,
  drawerContainerSelector: DRAWERS_CONTAINER_SELECTOR,
  id: undefined,
  noInitialFocus: false,
  onDestroy: noop,
};
