import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildMakeYearModelsPath, VehicleModel } from 'client/data/models/vehicle';

export const stateToProps = {
  options: bindToPath(
    ({ make, year, pubStates }) => make && year && buildMakeYearModelsPath(make, year, pubStates),
    VehicleModel
  ),
};

export function ModelDropdownUI({ isStyled, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  return <SelectComponent {...restProps} />;
}

ModelDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
};

ModelDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
};

export const ModelDropdown = connectToModel(ModelDropdownUI, stateToProps);
