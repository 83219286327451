import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { isSelectDisabled } from 'site-modules/shared/components/select/utils';

import './styled-select.scss';

// Select with the blue arrow
const StyledSelectUI = (
  { size, wrapperClassName, className, overrideClasses, wrapperTag, formControlColor, wrapperRef, ...props },
  ref
) => {
  const classes = classnames('form-control py-0_5 pr-2_5 size-16', formControlColor, className);
  const Tag = wrapperTag;

  const { optionsGrouped, options, preSavedValue, disabled, useFloatingLabel } = props;

  return (
    <Tag
      ref={wrapperRef}
      className={classnames('styled-select chevron', wrapperClassName, {
        'size-lg': size === 'lg',
        'floating-label': useFloatingLabel,
        'select-disabled': isSelectDisabled({ optionsGrouped, options, preSavedValue, disabled }),
      })}
    >
      <Select className={overrideClasses || classes} ref={ref} {...props} />
    </Tag>
  );
};

export const StyledSelectPropTypes = {
  ...SelectPropTypes,
  size: PropTypes.oneOf(['lg']),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  overrideClasses: PropTypes.string,
  wrapperTag: PropTypes.string,
  wrapperRef: PropTypes.shape({ current: PropTypes.object }),
  formControlColor: PropTypes.string,
};

export const StyledSelect = forwardRef(StyledSelectUI);

StyledSelect.propTypes = {
  ...StyledSelectPropTypes,
};

StyledSelect.defaultProps = {
  ...SelectDefaultProps,
  size: null,
  wrapperClassName: '',
  className: '',
  overrideClasses: null,
  wrapperTag: 'div',
  wrapperRef: null,
  formControlColor: 'text-gray-darker',
};
